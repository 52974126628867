export default {
  title: 'Сертификаты',
  items: [
    {
      thumbnail: require('./sertificate-1.jpg'),
      src: require('./sertificate-big-1.jpg'),
      title: 'Сертификат (1)'
    },
    {
      thumbnail: require('./sertificate-2.jpg'),
      src: require('./sertificate-big-2.jpg'),
      title: 'Сертификат (2)'
    },
    {
      thumbnail: require('./sertificate-3.jpg'),
      src: require('./sertificate-big-3.jpg'),
      title: 'Сертификат (3)'
    },
    {
      thumbnail: require('./sertificate-4.jpg'),
      src: require('./sertificate-big-4.jpg'),
      title: 'Сертификат (4)'
    },
    {
      thumbnail: require('./sertificate-5.jpg'),
      src: require('./sertificate-big-5.jpg'),
      title: 'Сертификат (5)'
    }
  ]
}
