import React from 'react'
import './Youtube.css'

const Youtube = ({id}) => (
  <div className="Youtube">
    <div className="Youtube-wrapper">
      <iframe
        title="Видеоплеер Youtube"
        width="640"
        height="480"
        src={`https://www.youtube.com/embed/${id}?autoplay=1`}
        frameBorder="0"
        className="Youtube-player"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        ></iframe>
    </div>
  </div>
)

export default Youtube
