import React from 'react'
import Container from '../Container'
import Heading from '../Heading'
import Video from '../Video'
import './Testimonials.css'

const videos = [
  '5qv4i4l58r0',
  'toO4ejzR8bU',
  'uwj2Oh8JdU4'
]

const Testimonials = ({onClick}) => (
  <section className="Testimonials">
    <Container full>
      <Heading full>
        Отзывы наших клиентов
      </Heading>
      <div className="Testimonials-items">
        {videos.map((videoId, index) => (
          <div key={videoId} className="Testimonials-item">
            <Video id={videoId} onClick={onClick}/>
          </div>
        ))}
      </div>
    </Container>
  </section>
)

export default Testimonials
