import React, {Component} from 'react'
import './Input.css'

class Input extends Component {
  handleChange = (event) => {
    const {name, onChange} = this.props
    onChange(name, event.target.value)
  }

  render() {
    const {value, required, placeholder, className} = this.props
    return (
      <input
        value={value}
        type="text"
        className={className ? `Input ${className}` : 'Input'}
        onChange={this.handleChange}
        placeholder={placeholder}
        required={required}
      />
    )
  }
}

export default Input
