import React from 'react'
import './Heading.css'

const getClassName = (full, bright) => {
  return [['full', full], ['bright', bright]]
    .reduce((r, i) => i[1] ? r + ` Heading--${i[0]}` : r, 'Heading')
}

const Heading = ({children, full = false, bright = false}) => (
  <h2 className={getClassName(full, bright)}>
    <span className="Heading-text">
      {children}
    </span>
  </h2>
)

export default Heading
