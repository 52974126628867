import React, {Component} from 'react'
import './Gallery.css'

function loadImage(src, token) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.addEventListener('load', () => resolve())
    token.cancel = () => reject()
    img.src = src
  })
}

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.tokens = {}
    this.state = {
      cached: {},
      current: props.current,
      offset: props.current > 0 ? 1 : 0,
      startX: 0,
      offsetX: 0,
      isMoving: false,
      transition: true
    }
  }

  componentDidMount() {
    this.preloadImages()
  }

  componentWillUnmount() {
    Object.keys(this.tokens).forEach(id => {
      if (!this.state.cached[id]) {
        this.tokens[id].cancel()
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {offset, current, transition} = this.state
    this.preloadImages()
    if (offset === 0 && current > 0) {
      setTimeout(() => {
        this.setState(state => ({
          current: state.current - 1,
          offset: state.current > 1 ? 1 : 0,
          transition: false
        }))
      }, 300)
    } else if (offset === 2 && current < this.props.items.length - 1) {
      setTimeout(() => {
        this.setState((state, props) => ({
          current: state.current + 1,
          offset: 1,
          transition: false
        }))
      }, 300)
    } else if (current === 0 && offset === 1) {
      setTimeout(() => {
        this.setState((state, props) => ({
          current: state.current + 1,
          offset: 1,
          transition: false
        }))
      }, 300)
    } else if (transition === false) {
      setTimeout(() => this.setState({
        transition: true
      }), 20)
    }
  }

  preloadImages() {
    this.getItems().forEach(item => {
      if (!this.tokens[item.id]) {
        const token = {}
        this.tokens[item.id] = token
        loadImage(item.src, token)
          .then(() => this.setState(state => ({
            cached: {
              ...state.cached,
              [item.id]: true
            }
          }))).catch(() => console.log('Загрузка отменена'))
      }
    })
  }

  handlePrev = (event) => {
    event.stopPropagation()
    this.setState(state => ({
      offset: Math.max(state.offset - 1, 0)
    }))
  }

  handleNext = (event) => {
    event.stopPropagation()
    this.setState(state => ({
      offset: Math.min(state.offset + 1, 2)
    }))
  }

  handleStart = (event) => {
    if (event.touches.length > 0) {
      const {pageX} = event.touches[0]
      this.setState({
        isMoving: true,
        transition: false,
        offsetX: 0,
        startX: pageX
      })
    }
    this.setState({
      isMoving: true,
      transition: false
    })
  }

  handleMove = (event) => {
    if (event.touches && event.touches.length > 0) {
      const {pageX} = event.touches[0]
      this.setState(state => ({
        offsetX: pageX - state.startX
      }))
    }
  }

  handleEnd = (event) => {
    if (Math.abs(this.state.offsetX) > 10) {
      event.stopPropagation()
    }
    this.setState(({current, offset, offsetX}, {items}) => {
      const endState = {
        isMoving: false,
        transition: true,
        offsetX: 0
      }
      if (offsetX > 10 && current > 0) {
        return {
          ...endState,
          offset: Math.max(offset - 1, 0)
        }
      }
      if (offsetX < -10 && current < items.length - 1) {
        return {
          ...endState,
          offset: Math.min(offset + 1, 2)
        }
      }
      return endState
    })
  }

  getItems() {
    const {items} = this.props
    const {current} = this.state
    if (current === 0) {
      return items.slice(0, 2)
    } else if (current === items.length - 1) {
      return items.slice(-2)
    }
    return items.slice(current - 1, current + 2)
  }

  renderItem = ({id, src, title}) => {
    return (
      <div key={id} className="Gallery-item">
        {this.state.cached[id] === true && <img src={src} alt={title} className="Gallery-img"/>}
        {this.state.cached[id] !== true && <div className="Gallery-loading"></div>}
      </div>
    )
  }

  render() {
    const {current, offset} = this.state
    const {items} = this.props
    return (
      <div className="Gallery">
        <div
          className="Gallery-content"
          style={{
            transform: `translateX(-${this.state.offset * 33.33}%) translateX(${this.state.offsetX}px)`,
            transition: this.state.transition
              ? 'transform ease-out 0.3s'
              : 'none'
          }}
          onTouchStart={this.handleStart}
          onTouchEnd={this.handleEnd}
          onTouchMove={this.handleMove}
        >
          {this.getItems().map(this.renderItem)}
        </div>
        {current + offset > 0 && <div className="Gallery-prev" onClick={this.handlePrev}></div>}
        {current + offset < items.length && items.length > 1 && <div className="Gallery-next" onClick={this.handleNext}></div>}
      </div>
    )
  }
}

export default Gallery
