import React from "react"
import Button from "../Button"
import logo from "./logo.jpg"
import "./Header.css"
import whatsAppLogo from "./whatsapp.svg"

const Header = ({ onClick }) => (
  <header className="Header">
    <div className="Header-content">
      <div className="Header-logo">
        <img src={logo} alt="Металл Инвест" className="Header-logo-img" />
        <div className="Header-logo-title">
          Производство
          <br />
          Металлоконструкций
        </div>
      </div>
      <div className="Header-contacts">
        <span className="Header-address">Москва, Южнопортовая 21</span>
        <div className="Header-callback">
          <div className="Header-callback-buttons">
            <a href="tel:+74951081184" className="Header-phone">
              +7 (495) 108 1184
            </a>
            <a href="https://wa.me/79251264270" className="Header-whatsapp">
              +7 (925) 126 4270
            </a>
          </div>
          <br className="Header-phone-br" />
          <Button
            type="small"
            className="Header-order-call"
            actionPayload="header"
            actionType="callback"
            onClick={onClick}
          >
            Заказать звонок
          </Button>
        </div>
      </div>
    </div>
  </header>
)

export default Header
