export default [
  {
    title: 'Главная',
    url: '#offer'
  },
  {
    title: 'Наши преимущества',
    url: '#advantages'
  },
  {
    title: 'Наши работы',
    url: '#cases'
  },
  {
    title: 'Рассчитать стоимость',
    url: '#cost'
  },
  {
    title: 'Наше производство',
    url: '#production'
  },
  {
    title: 'Сертификаты',
    url: '#certificates'
  },
  {
    title: 'Контакты',
    url: '#contacts'
  }
]
