import React, {Component} from 'react'
import Container from '../Container'
import Heading from '../Heading'
import Button from '../Button'
import cases from './images'
import './Cases.css'

class Cases extends Component {
  saveRef = ref => this.container = ref

  constructor(props) {
    super(props)
    this.state = {
      showAll: 1,
      isReady: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    if (!this.state.isReady) {
      const target = this.container.offsetTop - 2000
      const scrollTop = window.scrollY
      if (typeof scrollTop === 'undefined' || scrollTop >= target) {
        this.setState({
          isReady: true
        })
      }
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    const id = event.target.tagName === 'A'
      ? event.target.getAttribute('data-id')
      : event.target.parentNode.getAttribute('data-id')
    this.props.onClick('gallery', {
      gallery: cases,
      index: parseInt(id)
    })
  }

  handleMore = () => {
    this.setState(state => ({
      showAll: state.showAll + 1
    }))
  }

  renderItem = ({thumbnail, src, title}, index) => {
    return (
      <div key={index} className="Cases-item">
        <a
          href={src}
          data-id={index}
          onClick={this.handleClick}
          className="Cases-link"
        >
          <img
            src={this.state.isReady ? thumbnail : ''}
            alt={title}
            className="Cases-img"
          />
        </a>
      </div>
    )
  }

  getItems() {
    const {showAll} = this.state
    return showAll < 9
      ? cases.items.slice(0, this.state.showAll * 9)
      : cases.items
  }

  render() {
    return (
      <section id="cases" className="Cases" ref={this.saveRef}>
        <Container full>
          <Heading full>
            Наши работы
          </Heading>
          <div className="Cases-items">
            {this.getItems().map(this.renderItem)}
          </div>
          {this.state.showAll < 9 && <div className="Cases-more">
            <Button type="outline" onClick={this.handleMore}>
              Смотреть еще
            </Button>
          </div>}
        </Container>
      </section>
    )
  }
}

export default Cases
