import React, {Component} from 'react'
import Button from '../Button'
import Input from '../Input'
import man from './man.png'
import './Form.css'
import sendOrder from 'api/order'

const getClassName = (popup, hidden) => {
  return [['popup', popup], ['hidden', hidden]]
    .reduce((r, i) => i[1] ? r + ` Form--${i[0]}` : r, 'Form')
}

class Form extends Component {
  saveRef = ref => this.form = ref

  constructor(props) {
    super(props)
    this.state = {
      isReady: !!props.popup,
      isPending: false,
      clientName: '',
      clientPhone: ''
    }
  }

  componentDidMount() {
    if (!this.props.popup) {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll = (event) => {
    if (!this.state.isReady && window.innerWidth >= 992) {
      const target = this.form.offsetTop - 2000
      const scrollTop = window.scrollY
      if (typeof scrollTop === 'undefined' || scrollTop >= target) {
        this.setState({
          isReady: true
        })
      }
    }
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  handleSubmit = (event) => {
    const {name, description, onSubmit, onError} = this.props
    const {clientName, clientPhone} = this.state
    event.preventDefault()
    this.setState({
      isPending: true
    })
    sendOrder({
      form: name,
      description,
      clientName,
      clientPhone
    }).then(() => {
      this.setState({
        isPending: false
      })
      if (typeof onSubmit === 'function') {
        onSubmit()
      }
    }).catch((error) => {
      this.setState({
        isPending: false
      })
      if (typeof onError === 'function') {
        onError()
      }
    })
  }

  handleClick = (event) => {
    event.stopPropagation()
  }

  handleClose = (event) => {
    const {onClose} = this.props
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  render() {
    const {offer, popup, hidden, className} = this.props
    const baseClass = getClassName(popup, hidden)
    return (
      <form
        ref={this.saveRef}
        className={className ? `${baseClass} ${className}` : baseClass}
        onSubmit={this.handleSubmit}
        onClick={this.handleClick}
      >
        <h3 className="Form-offer">
          {typeof offer === 'string' ? offer : offer.map((text, index) => (
            <span key={index} className="Form-line">
              {text}
            </span>
          ))}
        </h3>
        <div className="Form-fields">
          <Input
            name="clientName"
            className="Form-input"
            value={this.state.clientName}
            onChange={this.handleChange}
            placeholder="Ваше имя"
          />
          <Input
            name="clientPhone"
            className="Form-input"
            value={this.state.clientPhone}
            onChange={this.handleChange}
            placeholder="Ваш телефон*"
            required
          />
        </div>
        <p className="Form-note">
          * — Обязательное поле для заполнения
        </p>
        <div className="Form-submit">
          <Button isPending={this.state.isPending}>
            Отправить
          </Button>
        </div>
        <p className="Form-note">
          * — отправляя свои данные вы защищены федеральным законом «О защите персональных данных»
        </p>
        <div
          className="Form-guy"
          style={{
            backgroundImage: this.state.isReady || this.props.popup
              ? `url(${man})`
              : 'none'
          }}
        ></div>
        <div className="Form-close" onClick={this.handleClose}></div>
      </form>
    )
  }
}

export default Form
