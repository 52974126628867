export default [
  {
    icon: 'garage',
    title: [
      'Современный ',
      'красивый и надёжный ',
      'навес высокого ',
      'качества'
    ]
  },
  {
    icon: 'measurement',
    title: ['Бесплатный выезд ', 'замерщика']
  },
  {
    icon: 'cube',
    title: ['Бесплатный 3D макет']
  },
  {
    icon: 'delivery-ok',
    title: ['Бесплатную доставку']
  },
  {
    icon: 'certificate',
    title: ['Гарантию от ', 'производителя']
  }
]
