import React from 'react'
import Button from '../Button'
import './Notify.css'

const Notify = ({item}) => (
  <div className={item.type ? `Notify Notify--${item.type}` : 'Notify'}>
    <h3 className="Notify-message">
      {item.message}
    </h3>
    <p className="Notify-description">
      {item.description}
    </p>
    <Button>
      Хорошо
    </Button>
  </div>
)

export default Notify
