export default [
  {
    image: require('./note-1.jpg'),
    important: 'навесы изготавливаются только из качественного металла толщиной стенок 4 мм.',
    remember: 'что экономия и использование тонкого металла приводит к обрушению навеса.'
  },
  {
    image: require('./note-2.jpg'),
    important: 'используем поликарбонат толщиной 10 мм. Монтаж производим только с термошайбами и по правильной технологии.',
    remember: 'что неправильный монтаж поликарбоната приводит к его порче уже через год.'
  },
  {
    image: require('./note-3.jpg'),
    important: 'красим у себя на производстве в покрасочной камере. Качественная грунтовка и лакокрасочное покрытие материалами фирмы HAMMERAITE.',
    remember: 'что некачественная покраска приводит к коррозии и разрушению металла.'
  }
]
