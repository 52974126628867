export default {
  title: 'Скидки при заказе навеса',
  items: [
    {
      thumbnail: require('./discount-small-1.jpg'),
      src: require('./discount-1.jpg'),
      title: 'На откатные ворота'
    },
    {
      thumbnail: require('./discount-small-2.jpg'),
      src: require('./discount-2.jpg'),
      title: 'На красивую лестницу в дом'
    },
    {
      thumbnail: require('./discount-small-3.jpg'),
      src: require('./discount-3.jpg'),
      title: 'На комплекс барбекю'
    }
  ]
}
