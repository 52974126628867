import React, {Component} from 'react'
import Container from '../Container'
import Heading from '../Heading'
import brokenShed from './broken-shed.jpg'
import './Caution.css'

class Caution extends Component {
  saveRef = ref => this.container = ref

  constructor(props) {
    super(props)
    this.state = {
      isReady: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    if (!this.state.isReady) {
      const target = this.container.offsetTop - 2000
      const scrollTop = window.scrollY
      if (typeof scrollTop === 'undefined' || scrollTop >= target) {
        this.setState({
          isReady: true
        })
      }
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    this.props.onClick('gallery', brokenShed)
  }

  render() {
    return (
      <section className="Caution" ref={this.saveRef}>
        <Container>
          <Heading bright>
            Внимание!!!
          </Heading>
          <p className="Caution-text">
            * — Уважаемые клиенты! В последнее время участились случаи использования фотографий и видео наших работ непорядочными конкурентами. Помните, что желание найти дешевле — ведёт к экономии на качестве, и, в следствии, к печальному результату. Будьте внимательны при выборе исполнителя! Вот, что может случиться, когда нас копируют и дают низкие цены:
          </p>
          <a href={brokenShed} className="Caution-figure" onClick={this.handleClick}>
            <img
              src={this.state.isReady ? brokenShed : ''}
              alt="Прогнувшаяся крыша навеса"
              className="Caution-img"
            />
          </a>
        </Container>
      </section>
    )
  }
}

export default Caution
