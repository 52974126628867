import React from 'react'
import Container from '../Container'
import Heading from '../Heading'
import Icon from '../Icon'
import './Advantages.css'

const Advantages = ({id, title, items}) =>  (
  <section id={id} className="Advantages">
    <Container>
      <Heading>
        {title}
      </Heading>
      <ul className="Advantages-items">
        {items.map(({icon, title}, index) => (
          <li key={index} className="Advantages-item">
            <Icon name={icon}/>
            <div className="Advantages-title">
              {title.map((text, index) => (
                <div key={index} className="Advantages-line">
                  {text}
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </Container>
  </section>
)

export default Advantages
