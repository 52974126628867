import React, {Component} from 'react'
import './Button.css'

class Button extends Component {
  getClassBase() {
    const {type} = this.props
    if (Array.isArray(type)) {
      return `Button ${type.map(t => `Button--${t}`).join(' ')}`
    } else if (typeof type === 'string') {
      return `Button Button--${type}`
    }
    return 'Button'
  }

  getClassName() {
    const {className, isPending} = this.props
    const base = this.getClassBase() + (isPending ? ' Button--pending' : '')
    return className ? `${base} ${className}` : base
  }

  handleClick = (event) => {
    const {actionType, actionPayload, onClick} = this.props
    if (typeof onClick === 'function') {
      event.preventDefault()
      onClick(actionType, actionPayload)
    }
  }

  render() {
    return (
      <button className={this.getClassName()} onClick={this.handleClick}>
        {this.props.children}
      </button>
    )
  }
}

export default Button
