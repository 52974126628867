import React, { Component } from 'react'
import Container from '../Container'
import notes from './notes/index'
import './Notes.css'

class Notes extends Component {
	saveRef = (ref) => (this.container = ref)

	constructor(props) {
		super(props)
		this.state = {
			isReady: false,
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	handleScroll = (event) => {
		if (!this.state.isReady) {
			const target = this.container.offsetTop - 2000
			const scrollTop = window.scrollY
			if (typeof scrollTop === 'undefined' || scrollTop >= target) {
				this.setState({
					isReady: true,
				})
			}
		}
	}

	renderItem = ({ image, important, remember }, index) => {
		return (
			<div key={index} className='Notes-item'>
				<div className='Notes-figure'>
					<img
						src={this.state.isReady ? image : ''}
						alt={`Совет ${index + 1}`}
						className='Notes-img'
					/>
				</div>
				<div className='Notes-body'>
					<p className='Notes-assert'>
						<strong className='Notes-strong'>Важно: </strong>
						<span className='Notes-text'>{important}</span>
					</p>
					<div className='Notes-line'></div>
					<p className='Notes-assert'>
						<strong className='Notes-strong'>Помните: </strong>
						<span className='Notes-text'>{remember}</span>
					</p>
				</div>
			</div>
		)
	}

	render() {
		return (
			<section className='Notes' ref={this.saveRef}>
				<Container>{notes.map(this.renderItem)}</Container>
			</section>
		)
	}
}

export default Notes
