import React from 'react'
import Container from '../Container'
import Heading from '../Heading'
import Calc from './Calc'
import './Cost.css'

const Cost = ({onSubmit, onError}) => (
  <section id="cost" className="Cost">
    <Container>
      <Heading>
        Узнайте стоимость своего навеса
      </Heading>
      <Calc onSubmit={onSubmit} onError={onError}/>
    </Container>
  </section>
)

export default Cost
