import React, { Component } from 'react'
import Heading from '../Heading'
import Container from '../Container'
import projects from './projects/index'
import './Projects.css'

class Projects extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isReady: false,
		}
	}

	componentDidMount() {
		window.addEventListener('load', this.handleLoad)
	}

	handleLoad = (event) => {
		this.setState({
			isReady: true,
		})
	}

	handleClick = (event) => {
		event.preventDefault()
		const id =
			event.target.tagName === 'A'
				? event.target.getAttribute('data-id')
				: event.target.parentNode.getAttribute('data-id')
		this.props.onClick('gallery', {
			gallery: projects,
			index: parseInt(id),
		})
	}

	renderItem = ({ thumbnail, src, title }, index) => {
		const { isReady } = this.state
		return (
			<div key={index} className='Projects-item'>
				<div className='Projects-inner'>
					<a
						href={src}
						data-id={index}
						onClick={this.handleClick}
						className='Projects-link'
					>
						<img
							src={isReady ? thumbnail : ''}
							alt={title}
							className='Projects-img'
						/>
					</a>
					<span className='Projects-title'>{title}</span>
				</div>
			</div>
		)
	}

	render() {
		return (
			<section className='Projects'>
				<Container full>
					<Heading full>Наши проекты</Heading>
					<div className='Projects-items'>
						{projects.items.map(this.renderItem)}
					</div>
				</Container>
			</section>
		)
	}
}

export default Projects
