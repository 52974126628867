import React, {Component} from 'react'
import stubThumb from './stub.png'
import './Video.css'

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReady: false
    }
  }

  handleClick = (event) => {
    const {id, onClick} = this.props
    onClick('play', id)
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad)
  }

  handleLoad = (event) => {
    this.setState({
      isReady: true
    })
  }

  isBrokenVideo() {
    const {id} = this.props
    return (
      id === 'gF7YT7q9pMA' ||
      id === '5qv4i4l58r0'
    )
  }

  getImageSrc() {
    return this.state.isReady
      ? this.isBrokenVideo()
        ? `https://img.youtube.com/vi/${this.props.id}/0.jpg`
        : `https://img.youtube.com/vi/${this.props.id}/sddefault.jpg`
      : stubThumb
  }

  render() {
    return (
      <div className="Video">
        <img
          className="Video-thumbnail"
          src={this.getImageSrc()}
          alt="Превью"
        />
        <div href="#" className="Video-play" onClick={this.handleClick}></div>
      </div>
    )
  }
}

export default Video
