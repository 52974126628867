import React from 'react'
import './FieldSet.css'

const FieldSet = ({children, legend}) => (
  <div className="FieldSet">
    {legend && <h3 className="FieldSet-legend">{legend}</h3>}
    <div className="FieldSet-content">
      {children}
    </div>
  </div>
)

export default FieldSet
