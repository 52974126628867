import React, {Component} from 'react'
import './Nav.css'

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    this.setState(state => ({
      isOpen: !state.isOpen
    }))
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  handleNav = (event) => {
    const href = event.target.getAttribute('href')
    const target = document.getElementById(href.slice(1))
    if (
      typeof target.offsetTop !== 'undefined' &&
      typeof window.scrollTo === 'function' &&
      typeof window.history !== 'undefined'
    ) {
      event.preventDefault()
      window.history.pushState({}, '', href)
      this.smoothScrollTo(target.offsetTop)
    }
    this.setState({
      isOpen: false
    })
  }

  smoothScrollTo(end) {
    const start = window.scrollY
    const step = (end - start) / 62
    let current = start
    this.interval = setInterval(() => {
      window.scrollTo(0, current += step)
      if ((start < end && current >= end) || (start >= end && current <= end)) {
        clearInterval(this.interval)
        this.interval = null
      }
    }, 16)
  }

  renderItem = (item, index) => {
    return (
      <li key={index} className="Nav-item">
        <a href={item.url} className="Nav-link" onClick={this.handleNav}>
          {item.title}
        </a>
      </li>
    )
  }

  render() {
    return (
      <nav className={this.state.isOpen ? 'Nav Nav--open' : 'Nav'}>
        <div className="Nav-content">
          <ul className="Nav-items">
            {this.props.items.map(this.renderItem)}
          </ul>
          <div className="Nav-shadow"></div>
          <button className="Nav-burger" onClick={this.handleClick}>
            <span className="Nav-slice"></span>
            <span className="Nav-slice"></span>
            <span className="Nav-slice"></span>
          </button>
        </div>
      </nav>
    )
  }
}

export default Nav
