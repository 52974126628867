import React, { Component } from 'react'
import Container from '../Container'
import Heading from '../Heading'
import discounts from './discounts/index'
import './Discounts.css'

class Discounts extends Component {
	saveRef = (ref) => (this.container = ref)

	constructor(props) {
		super(props)
		this.state = {
			isReady: false,
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	handleScroll = (event) => {
		if (!this.state.isReady) {
			const target = this.container.offsetTop - 1000
			const scrollTop = window.scrollY
			if (typeof scrollTop === 'undefined' || scrollTop >= target) {
				this.setState({
					isReady: true,
				})
			}
		}
	}

	handleClick = (event) => {
		event.preventDefault()
		const id =
			event.target.tagName === 'A'
				? event.target.getAttribute('data-id')
				: event.target.parentNode.getAttribute('data-id')
		this.props.onClick('gallery', {
			gallery: discounts,
			index: parseInt(id),
		})
	}

	renderItem = ({ thumbnail, src, title }, index) => {
		return (
			<div key={index} className='Discounts-item'>
				<div className='Discounts-inner'>
					<a
						href={src}
						data-id={index}
						onClick={this.handleClick}
						className='Discounts-link'
					>
						<img
							src={this.state.isReady ? thumbnail : ''}
							alt={title}
							className='Discounts-img'
						/>
					</a>
					<span className='Discounts-title'>{title}</span>
				</div>
			</div>
		)
	}

	render() {
		return (
			<section className='Discounts' ref={this.saveRef}>
				<Container full>
					<Heading bright full>
						При заказе навеса вы получаете скидку
					</Heading>
					<div className='Discounts-items'>
						{discounts.items.map(this.renderItem)}
					</div>
				</Container>
			</section>
		)
	}
}

export default Discounts
