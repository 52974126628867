export default [
  {
    icon: 'crane',
    title: [
      'Собственное ', 'производство в ', 'Москве'
    ]
  },
  {
    icon: 'worker',
    title: [
      'Высококвалифицированный ',
      'штат работников с ',
      'многолетним опытом'
    ]
  },
  {
    icon: 'delivery',
    title: ['Собственный ', 'автопарк']
  },
  {
    icon: 'calendar',
    title: ['Короткие сроки ', 'исполнения заказа']
  },
  {
    icon: 'discount',
    title: ['Работаем без ', 'предоплаты']
  }
]
