import axios from "axios"

// TODO check form name parser
const REGISTRED_FORM_NAMES = ["otpravit", "stoimost_navesa"]

const formNameParser = (name) => {
	return name && REGISTRED_FORM_NAMES.includes(name) ? name : "zayavka"
}

const sendOrder = async (order) => {
	if (typeof window.ym !== "undefined") {
		const metricName = formNameParser(order.form)
		await window.ym(52299730, "reachGoal", metricName)
		// ym(52299730,'reachGoal','stoimost_navesa')
	}

	return axios.post("/order.php", order)
}

export default sendOrder
