import React from 'react'
import Heading from '../Heading'
import Container from '../Container'
import Video from '../Video'
import './About.css'

const About = ({onClick}) => (
  <section className="About">
    <Container>
      <Heading>
        О наших навесах
      </Heading>
      <p className="About-text">
        Наши навесы — это современное, качественное, стильное украшение Вашего дома по адекватной цене. Мы делаем навесы различного уровня сложности с 2009 года. Все работы выполняем самостоятельно на своём производстве в Москве. Вы всегда можете приехать к нам на производство и убедиться в качестве материалов и нашей работы. Последние снежные зимы и ураганы доказали, что наши навесы не только самые красивые, но и самые надёжные!
      </p>
      <div className="About-video">
        <Video id="H05PF1TIeiI" onClick={onClick}/>
      </div>
    </Container>
  </section>
)

export default About
