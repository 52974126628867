import React, {Component} from 'react'
import Container from '../Container'
import load from './load-calculation.docx'
import scheme from './scheme.png'
import './Load.css'

class Load extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isReady: false
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad)
  }

  handleLoad = (event) => {
    this.setState({
      isReady: true
    })
  }

  render() {
    return (
      <section className="Load">
        <Container>
          <h2 className="Load-heading">
            Расчет нагрузок
          </h2>
          <img src={this.state.isReady ? scheme : ''} alt="Расчетная схема" className="Load-figure"/>
          <div className="Load-wrapper">
            <a href={load} rel="noopener noreferrer" target="_blank" className="Load-link">
              Скачать
            </a>
          </div>
        </Container>
      </section>
    )
  }
}

export default Load
