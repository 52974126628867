import React, {Component} from 'react'
import './Radio.css'

class Radio extends Component {
  handleClick = (event) => {
    const {name, value, itemValue, isCheckbox, onChange} = this.props
    onChange(name, isCheckbox ? !value : itemValue)
  }

  getClassName() {
    const {value, itemValue, className} = this.props
    const base = value === itemValue
      ? "Radio Radio--checked"
      : "Radio"
    return className ? `${base} ${className}` : base
  }

  render() {
    return (
      <div className={this.getClassName()} onClick={this.handleClick}>
        <span className="Radio-button"></span>
        <span className="Radio-title">
          {this.props.title}
        </span>
      </div>
    )
  }
}

export default Radio
