import React, {Component} from 'react'
import Container from '../Container'
import Heading from '../Heading'
import images from './images'
import Form from '../Form'
import './Certificates.css'

class Certificates extends Component {
  saveRef = ref => this.container = ref

  constructor(props) {
    super(props)
    this.state = {
      isReady: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    if (!this.state.isReady) {
      const target = this.container.offsetTop - 2000
      const scrollTop = window.scrollY
      if (typeof scrollTop === 'undefined' || scrollTop >= target) {
        this.setState({
          isReady: true
        })
      }
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    const id = event.target.tagName === 'A'
      ? event.target.getAttribute('data-id')
      : event.target.parentNode.getAttribute('data-id')
    this.props.onClick('gallery', {
      gallery: images,
      index: parseInt(id)
    })
  }

  renderItem = ({thumbnail, src, title}, index) => {
    return (
      <div key={index} className="Certificates-item">
        <a
          href={src}
          data-id={index}
          onClick={this.handleClick}
          className="Certificates-link"
        >
          <img
            src={this.state.isReady ? thumbnail : ''}
            alt={title}
            className="Certificates-img"
          />
        </a>
      </div>
    )
  }

  render() {
    return (
      <section id="certificates" className="Certificates" ref={this.saveRef}>
        <Container full>
          <Heading full>
            Сертификаты
          </Heading>
          <div className="Certificates-items">
            {images.items.map(this.renderItem)}
          </div>
          <div className="Certificates-form">
            <Form
              name="questions"
              description="Консультация"
              offer={[
                'Остались вопросы? ',
                'Позвоните или закажите звонок и мы с ',
                'радостью Вас проконсультируем'
              ]}
              onSubmit={this.props.onSubmit}
              onError={this.props.onError}
              hidden
            />
          </div>
        </Container>
      </section>
    )
  }
}

export default Certificates
