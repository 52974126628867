export default {
  title: 'Наши проекты',
  items: [
    {
      thumbnail: require('./arched-small.jpg'),
      src: require('./arched.jpg'),
      title: 'Арочный'
    },
    {
      thumbnail: require('./gable-small.jpg'),
      src: require('./gable.jpg'),
      title: 'Двускатный'
    },
    {
      thumbnail: require('./pent-small.jpg'),
      src: require('./pent.jpg'),
      title: 'Односкатный'
    },
    {
      thumbnail: require('./semi-arched-small.jpg'),
      src: require('./semi-arched.jpg'),
      title: 'Полуарочный'
    }
  ]
}
