export default {
  title: 'Наше производство',
  items: [
    {
      thumbnail: require('./production-small-1.jpg'),
      src: require('./production-1.jpg'),
      title: 'Производство (1)'
    },
    {
      thumbnail: require('./production-small-2.jpg'),
      src: require('./production-2.jpg'),
      title: 'Производство (2)'
    },
    {
      thumbnail: require('./production-small-3.jpg'),
      src: require('./production-3.jpg'),
      title: 'Производство (3)'
    },
    {
      thumbnail: require('./production-small-4.jpg'),
      src: require('./production-4.jpg'),
      title: 'Производство (4)'
    },
    {
      thumbnail: require('./production-small-5.jpg'),
      src: require('./production-5.jpg'),
      title: 'Производство (5)'
    },
    {
      thumbnail: require('./production-small-6.jpg'),
      src: require('./production-6.jpg'),
      title: 'Производство (6)'
    }
  ]
}
