import React from "react"
import Container from "../Container"
import Heading from "../Heading"
import Form from "../Form"
import "./Measurement.css"

const Measurement = ({ onError, onSubmit }) => (
	<section className="Measurement">
		<Container>
			<Heading>Заказать навес</Heading>
			<Form
				//Requested by SME
				name="otpravit"
				// name="measurement"
				description="Бесплатный замер"
				offer="Оформите заявку на бесплатный замер"
				onSubmit={onSubmit}
				onError={onError}
			/>
		</Container>
	</section>
)

export default Measurement
