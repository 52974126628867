import React from 'react'
import './Container.css'

const Container = ({children, full = false}) => (
  <div className={full ? "Container Container--full" : "Container"}>
    {children}
  </div>
)

export default Container
