import React, { Component } from 'react'
import Nav from '../Nav'
import Header from '../Header'
import Offer from '../Offer'
import About from '../About'
import Load from '../Load'
import Cost from '../Cost'
import Cases from '../Cases'
import Projects from '../Projects/Projects'
// import Projects from '../Projects'
import Discounts from '../Discounts'
import Testimonials from '../Testimonials'
import Notes from '../Notes'
import Caution from '../Caution'
import Advantages from '../Advantages'
import Measurement from '../Measurement'
import Production from '../Production'
import Certificates from '../Certificates'
import Footer from '../Footer'
import Form from '../Form'
import Youtube from '../Youtube'
import Gallery from '../Gallery'
import Notify from '../Notify'
import navItems from './navItems'
import advantages from './advantages'
import benefits from './benefits'
import './App.css'

const formsData = {
	header: {
		formName: 'callback',
		formOffer: 'Заказать звонок',
		formDescription: 'Запрос звонка из заголовка',
	},
	offer: {
		formName: 'offer-order',
		formOffer: 'Заказать замер бесплатно',
		formDescription: 'Заказ замера с верхнего экрана',
	},
}

const popupStages = {
	hidden: 'hidden',
	open: 'open',
	closing: 'closing',
}

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			popup: null,
			popupStage: popupStages.hidden,
			galleryIndex: 0,
			galleryTitle: '',
			galleryItems: [],
			formName: '',
			formOffer: 'Примерная форма',
			formDescription: '',
			youtubeVideo: '',
			notify: null,
		}
	}

	componentDidUpdate() {
		if (this.state.popupStage === popupStages.closing) {
			setTimeout(
				() =>
					this.setState({
						popup: null,
						popupStage: popupStages.hidden,
					}),
				400,
			)
		}
	}

	handleClick = (type, payload) => {
		if (type === 'callback') {
			this.setState({
				popup: 'form',
				popupStage: popupStages.open,
				formName: formsData[payload].formName,
				formOffer: formsData[payload].formOffer,
				formDescription: formsData[payload].formDescription,
			})
		} else if (type === 'play') {
			this.setState({
				popup: 'youtube',
				popupStage: popupStages.open,
				youtubeVideo: payload,
			})
		} else if (type === 'gallery') {
			this.setState({
				popup: 'gallery',
				popupStage: popupStages.open,
				galleryIndex: typeof payload !== 'string' ? payload.index : 0,
				galleryTitle: typeof payload !== 'string' ? payload.gallery.title : '',
				galleryItems:
					typeof payload !== 'string'
						? payload.gallery.items.map((item, id) => ({ ...item, id }))
						: [{ id: 1, src: payload, title: '' }],
			})
		}
	}

	handleClose = (event) => {
		this.setState({
			popupStage: popupStages.closing,
		})
	}

	handleSubmit = (event) => {
		this.setState({
			popup: 'notify',
			popupStage: popupStages.open,
			notify: {
				type: 'success',
				message: 'Спасибо за ваше обращение!',
				description: 'Мы свяжемся с вами в ближайшее время',
			},
		})
	}

	handleError = (event) => {
		this.setState({
			popup: 'notify',
			popupStage: popupStages.open,
			notify: {
				type: 'error',
				message: 'Не удалось отправить заявку!',
				description:
					'Проверьте соединение с интернетом и попробуйте отправить ещё раз',
			},
		})
	}

	render() {
		const { popup, popupStage } = this.state
		return (
			<div className='App'>
				<Nav items={navItems} />
				<Header onClick={this.handleClick} />
				<Offer id='offer' onClick={this.handleClick} />
				<About onClick={this.handleClick} />
				<Projects onClick={this.handleClick} />
				<Load />
				<Cost onSubmit={this.handleSubmit} onError={this.handleError} />
				<Cases onClick={this.handleClick} />
				<Testimonials onClick={this.handleClick} />
				<Discounts onClick={this.handleClick} />
				<Notes />
				<Caution onClick={this.handleClick} />
				<Advantages
					id='advantages'
					title='Наши преимущества'
					items={advantages}
				/>
				<Advantages
					id='benefits'
					title='Что получают наши клиенты'
					items={benefits}
				/>
				<Measurement onSubmit={this.handleSubmit} onError={this.handleError} />
				<Production onClick={this.handleClick} />
				<Certificates
					onSubmit={this.handleSubmit}
					onError={this.handleError}
					onClick={this.handleClick}
				/>
				<Footer />
				<div
					className={
						popupStage !== popupStages.hidden
							? `App-shadow App-shadow--${popupStage}`
							: 'App-shadow'
					}
					onClick={this.handleClose}
				>
					<div
						className={
							popupStage !== popupStages.hidden
								? `App-popup App-popup--${popupStage}`
								: 'App-popup'
						}
					>
						{popup === 'form' && (
							<Form
								name={this.state.formName}
								description={this.state.formDescription}
								offer={this.state.formOffer}
								onSubmit={this.handleSubmit}
								onError={this.handleError}
								onClose={this.handleClose}
								popup
							/>
						)}
						{popup === 'youtube' && <Youtube id={this.state.youtubeVideo} />}
						{popup === 'gallery' && (
							<Gallery
								current={this.state.galleryIndex}
								title={this.state.galleryTitle}
								items={this.state.galleryItems}
							/>
						)}
						{popup === 'notify' && <Notify item={this.state.notify} />}
					</div>
				</div>
			</div>
		)
	}
}

export default App
