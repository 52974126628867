import React, { Component } from "react"
import Container from "../Container"
import "./Footer.css"

class Footer extends Component {
  saveRef = (ref) => (this.container = ref)

  constructor(props) {
    super(props)
    this.state = {
      isReady: false
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = (event) => {
    if (!this.state.isReady) {
      const target = this.container.offsetTop - 2000
      const scrollTop = window.scrollY
      if (typeof scrollTop === "undefined" || scrollTop >= target) {
        this.setState({
          isReady: true
        })
      }
    }
  }

  render() {
    return (
      <footer id="contacts" className="Footer" ref={this.saveRef}>
        <div className="Footer-contacts">
          <Container>
            <h3 className="Footer-title">Контакты</h3>
            <ul className="Footer-list">
              <li className="Footer-item">
                <a
                  href="https://maps.google.com/maps?ll=55.706832,37.698055&z=14&t=m&hl=ru&gl=RU&mapclient=embed&q=%D0%AE%D0%B6%D0%BD%D0%BE%D0%BF%D0%BE%D1%80%D1%82%D0%BE%D0%B2%D0%B0%D1%8F%20%D1%83%D0%BB.%2C%2021%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0%20109052"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="Footer-link"
                >
                  г. Москва, ул. Южнопортовая 21
                </a>
              </li>
              <li className="Footer-item">
                <a
                  href="tel:+74951081184"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="Footer-link"
                >
                  +7 (495) 108 1184
                </a>
              </li>
              <li className="Footer-item">
                <a
                  href="mailto:avto-naves2016@yandex.ru"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="Footer-link"
                >
                  Email: avto-naves2016@yandex.ru
                </a>
              </li>
            </ul>
            {/* <div className='Footer-socials'>
							Мы в соцсетях:
							<a
								className='Footer-icon Footer-icon--youtube'
								rel='noopener noreferrer'
								target='_blank'
								href='https://www.youtube.com/channel/UCcOy-VLBu91esvC_rC_BP8w?view_as=subscriber'
							>
								Youtube
							</a>
							<a
								className='Footer-icon Footer-icon--instagram'
								rel='noopener noreferrer'
								target='_blank'
								href='https://www.instagram.com/metallinvest777/'
							>
								Instagram
							</a>
						</div> */}
          </Container>
        </div>
        <div className="Footer-map">
          {this.state.isReady && (
            <iframe
              title="Карта"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17985.54304569482!2d37.708686!3d55.703019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414ab4d954e47e2f%3A0xf01c9cedbd8e518a!2z0K7QttC90L7Qv9C-0YDRgtC-0LLQsNGPINGD0LsuLCAyMSwg0JzQvtGB0LrQstCwLCAxMDkwNTI!5e0!3m2!1sru!2sru!4v1584876033196!5m2!1sru!2sru"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          )}
        </div>
        <div
          className="Footer-copyright"
          style={{
            color: "#FFF",
            display: "flex",
            justifyContent: "space-between",
            padding: "0 40px"
          }}
        >
          <p>
            &copy; {new Date().getFullYear()}{" "}
            <strong style={{ color: "orange" }}>Металл</strong> Инвест
          </p>
          <p>
            Все права защищены. Информация на сайте не является публичной
            офертой.
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer
