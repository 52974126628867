import React from 'react'
import Button from '../Button'
import './Offer.css'

const Offer = ({id, onClick}) => (
  <section id={id} className="Offer">
    <div className="Offer-wrapper">
      <h1 className="Offer-heading">
        <strong className="Offer-strong">Автомобильные навесы </strong>
        <br className="Offer-br"/>
        премиум качества от производителя без предоплаты
      </h1>
      <p className="Offer-description">
        Бесплатный выезд замерщика,
        бесплатный 3d проект,
        бесплатная доставка
      </p>
    </div>
    <Button
      className="Offer-order"
      actionType="callback"
      actionPayload="offer"
      onClick={onClick}
    >
      Заказать замер<br className="Offer-order-br"/> бесплатно
    </Button>
  </section>
)

export default Offer
