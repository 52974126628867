import React, { Component } from "react"
import FieldSet from "./FieldSet"
import Select from "./Select"
import Radio from "./Radio"
import Input from "../Input"
import Button from "../Button"
import "./Calc.css"
import sendOrder from "api/order"

const roofTypes = [
	{ value: "arched", title: "Арочный" },
	{ value: "gable", title: "Двускатный" },
	{ value: "pent", title: "Односкатный" },
	{ value: "semi-arched", title: "Полуарочный" }
]

const widthOptions = [
	{ value: null, title: "По кровле / По стойкам" },
	{ value: 4.4, title: "4.4 / 4.0" }, //5000
	{ value: 4.9, title: "4.9 / 4.5" }, //4700
	{ value: 5.4, title: "5.4 / 5.0" }, //4600
	{ value: 5.9, title: "5.9 / 5.5" }, //4600
	{ value: 6.4, title: "6.4 / 6.0" }, //4500
	{ value: 6.9, title: "6.9 / 6.5" }, //4500
	{ value: 7.4, title: "7.4 / 7.0" },
	{ value: 7.9, title: "7.9 / 7.5" },
	{ value: 8.4, title: "8.4 / 8.0" },
	{ value: 8.9, title: "8.9 / 8.5" },
	{ value: 9.4, title: "9.4 / 9.0" }
]

const lengthOptions = [
	{ value: null, title: "По кровле / По стойкам" },
	// {value: 4.2, title: '4.2 / 3.2'},
	{ value: 5.3, title: "5.3 / 4.3" },
	{ value: 6.3, title: "6.3 / 5.3" },
	{ value: 7.4, title: "7.4 / 6.4" },
	{ value: 8.5, title: "8.5 / 7.5" },
	{ value: 9.5, title: "9.5 / 8.5" },
	{ value: 10.6, title: "10.6 / 9.6" },
	{ value: 11.7, title: "11.7 / 10.7" },
	{ value: 12.7, title: "12.7 / 11.7" },
	{ value: 13.8, title: "13.8 / 12.8" },
	{ value: 14.8, title: "14.8 / 13.8" }
]

const roofClasses = [
	{ value: null, title: "Выберите класс навеса" },
	{ value: "standart", title: "Стандарт — стойки 80*80" },
	{ value: "premium", title: "Премиум — стойки 120*120" }
]

const roofClassesPremium = roofClasses.filter(
	({ value }) => value !== "standart"
)

class Calc extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPending: false,
			roofType: 0,
			width: 0,
			length: 0,
			concreting: null,
			distance: null,
			roofClass: null,
			clientName: "",
			clientPhone: "",
			noRobot: false,
			disabledBtn: false,
			helperError: {
				width: false,
				length: false,
				roofClass: false
			},
			prices: {
				standart_all: 4500,
				premium_all: 5400
			}
		}
	}

	// componentDidMount() {
	// 	const fetchPrices = async () => {
	// 		try {
	// 			const response = await fetch(
	// 				'https://europe-west2-price-manager-b4d64.cloudfunctions.net/prices',
	// 			)
	// 			const data = await response.json()
	// 			this.setState({ prices: data })
	// 		} catch (e) {
	// 			console.error('firebase cloud error', e)
	// 		}
	// 	}
	// 	fetchPrices()
	// }

	handleChange = (name, value) => {
		this.setState((state) => {
			return name === "width" && value >= 9
				? {
						roofClass: state.roofClass > 0 ? 1 : 0,
						width: value
				  }
				: {
						[name]: value
				  }
		})
	}

	handleSubmit = (event) => {
		const { onSubmit, onError } = this.props
		event.preventDefault()
		const { width, length, roofClass } = this.state
		if (!width || !length || !roofClass) {
			this.setState({
				disabledBtn: true
			})
			setTimeout(() => {
				this.setState({
					disabledBtn: false
				})
			}, 500)

			if (!width)
				this.setState((state) => ({
					helperError: { ...state.helperError, width: true }
				}))
			if (!length)
				this.setState((state) => ({
					helperError: { ...state.helperError, length: true }
				}))
			if (!roofClass)
				this.setState((state) => ({
					helperError: { ...state.helperError, roofClass: true }
				}))
			return
		}

		this.setState({
			isPending: true
		})
		sendOrder(this.prepareOrder())
			.then(() => {
				this.setState({
					isPending: false
				})
				if (typeof onSubmit === "function") {
					onSubmit()
				}
			})
			.catch(() => {
				this.setState({
					isPending: false
				})
				if (typeof onError === "function") {
					onError()
				}
			})
	}

	prepareOrder() {
		return {
			//Requested by SME
			form: "stoimost_navesa",
			// form: "calc",
			description: "Расчет",
			roofType: roofTypes[this.state.roofType].title,
			width: widthOptions[this.state.width].title,
			length: lengthOptions[this.state.length].title,
			concreting:
				this.state.concreting === "area"
					? "есть бетонная площадка"
					: this.state.concreting === "bars"
					? "бетонируем стойки"
					: "N/A",
			distance:
				this.state.distance !== null ? `${this.state.distance} км` : "N/A",
			roofClass:
				this.state.width >= 9
					? roofClassesPremium[this.state.roofClass].title
					: roofClasses[this.state.roofClass].title,
			clientName: this.state.clientName,
			clientPhone: this.state.clientPhone
		}
	}

	calcResult() {
		const { width, length, roofClass } = this.state
		if (width !== 0 && length !== 0 && roofClass && roofClass !== 0) {
			const widthValue = widthOptions[width].value
			const lengthValue = lengthOptions[length].value

			//120x120
			const getPriceWidthPremium = (width) => {
				return this.state.prices.premium_all
				// switch (true) {
				// 	case width === 4.4:
				// 		return 5800
				// 	case width === 4.9:
				// 		return 5600
				// 	case width === 5.4:
				// 		return 5600
				// 	case width === 5.9:
				// 		return 5500
				// 	default:
				// 		return 5400
				// }
			}
			//80x80
			const getPriceWidthStandart = (width) => {
				return this.state.prices.standart_all
				// switch (true) {
				// 	case width === 4.4:
				// 		return 5000
				// 	case width === 4.9:
				// 		return 4800
				// 	case width === 5.4:
				// 		return 4700
				// 	case width === 5.9:
				// 		return 4700
				// 	default:
				// 		return 4500
				// }
			}

			// const classRate = getPriceWidthPremium (widthValue);
			const filtredRoofClasses =
				this.state.width >= 9 ? roofClassesPremium : roofClasses
			const classRate =
				filtredRoofClasses[roofClass].value === "standart"
					? getPriceWidthStandart(widthValue)
					: getPriceWidthPremium(widthValue)

			// const classRate = widthValue < 8.4
			//   ? roofClass === 1 ? 3500 : 4200
			//   : 4200;
			//  8.4 * 8.5

			// widthValue < 8.4 ? (roofClass === 1 ? 3000 : 3600) : 3600
			const area = widthValue * lengthValue
			const price = Math.round(area * classRate)
			const visibleArea = Math.round(area * 100) / 100
			const visiblePrice = price
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
			return (
				<div className="Calc-result">
					Стоимость навеса:
					<span className="Calc-number">{` ${visiblePrice} руб`}</span> /
					Накрываемая площадь:
					<span className="Calc-number">{` ${visibleArea} кв.м.`}</span>
				</div>
			)
		}
		return (
			<div className="Calc-result">
				Заполните форму выше для произведения расчета
			</div>
		)
	}

	isDisabled() {
		return (
			!this.state.noRobot ||
			this.state.clientPhone.length === 0 ||
			this.state.disabledBtn
		)
	}

	render() {
		return (
			<form className="Calc" onSubmit={this.handleSubmit}>
				<div className="Calc-fields">
					<FieldSet legend="Тип навеса">
						<Select
							name="roofType"
							value={this.state.roofType}
							items={roofTypes}
							onChange={this.handleChange}
						/>
					</FieldSet>
					<FieldSet legend="Ширина, м">
						<Select
							name="width"
							value={this.state.width}
							items={widthOptions}
							onChange={this.handleChange}
							helperError={this.state.helperError.width}
							resetHelperError={() =>
								this.setState((state) => ({
									...state,
									helperError: { ...state.helperError, width: false }
								}))
							}
						/>
					</FieldSet>
					<FieldSet legend="Длина, м">
						<Select
							name="length"
							value={this.state.length}
							items={lengthOptions}
							onChange={this.handleChange}
							helperError={this.state.helperError.length}
							resetHelperError={() =>
								this.setState((state) => ({
									...state,
									helperError: { ...state.helperError, length: false }
								}))
							}
						/>
					</FieldSet>
				</div>
				<div className="Calc-fields">
					<FieldSet>
						<Radio
							name="concreting"
							title="Бетонируем стойки"
							value={this.state.concreting}
							itemValue="bars"
							onChange={this.handleChange}
						/>
						<Radio
							name="concreting"
							title="Есть бетонная площадка"
							value={this.state.concreting}
							itemValue="area"
							onChange={this.handleChange}
						/>
					</FieldSet>
					<FieldSet legend="Удаленность от МКАД">
						<Radio
							name="distance"
							title="до 50 км"
							value={this.state.distance}
							itemValue={50}
							onChange={this.handleChange}
						/>
						<Radio
							name="distance"
							title="до 100 км"
							value={this.state.distance}
							itemValue={100}
							onChange={this.handleChange}
						/>
						<Radio
							name="distance"
							title="до 150 км"
							value={this.state.distance}
							itemValue={150}
							onChange={this.handleChange}
						/>
					</FieldSet>
					<FieldSet legend="Выберите класс навеса">
						<Select
							name="roofClass"
							value={this.state.roofClass}
							items={this.state.width >= 9 ? roofClassesPremium : roofClasses}
							onChange={this.handleChange}
							helperError={this.state.helperError.roofClass}
							resetHelperError={() =>
								this.setState((state) => ({
									...state,
									helperError: { ...state.helperError, roofClass: false }
								}))
							}
						/>
					</FieldSet>
				</div>
				<div className="Calc-footer">
					<div className="Calc-footer-inner">
						{this.calcResult()}
						<Input
							name="clientName"
							value={this.state.clientName}
							onChange={this.handleChange}
							placeholder="Ваше имя"
						/>
						<Input
							name="clientPhone"
							value={this.state.clientPhone}
							onChange={this.handleChange}
							placeholder="Ваш телефон*"
							required
						/>
						<Radio
							name="noRobot"
							title="Я не робот"
							value={this.state.noRobot}
							itemValue={true}
							onChange={this.handleChange}
							isCheckbox={true}
							className="Calc-noRobot"
						/>
						<Button
							type={this.isDisabled() ? "disabled" : null}
							className="Calc-order"
							isPending={this.state.isPending}
						>
							Заказать
						</Button>
						<p className="Calc-note">
							* — отправляя свои данные вы защищены федеральным законом «О
							защите персональных данных»
						</p>
						<p className="Calc-note">
							* — полная стоимость зависит от типа монтажа и удаленности от
							Москвы
						</p>
					</div>
				</div>
			</form>
		)
	}
}

export default Calc
