import React, { Component } from 'react'
import './Select.css'

const stages = {
	normal: 'normal',
	open: 'open',
	closing: 'closing',
}

class Select extends Component {
	saveListRef = (ref) => (this.list = ref)

	constructor(props) {
		super(props)
		this.state = {
			stage: stages.normal,
			height: 0,
		}
	}

	componentDidMount() {
		this.setState({
			height: this.list.clientHeight,
		})
	}

	componentDidUpdate() {
		if (this.state.stage === stages.closing) {
			setTimeout(() => {
				this.setState({
					stage: stages.normal,
				})
			}, 400)
		}
	}

	handleClick = (event) => {
		this.setState((state) => ({
			stage: state.stage === stages.normal ? stages.open : stages.closing,
		}))
	}

	handleChange = (event) => {
		const { name, onChange, resetHelperError } = this.props
		const value = parseInt(event.target.getAttribute('data-id'))
		this.setState({
			stage: stages.closing,
		})
		onChange(name, value)
		if (resetHelperError) {
			resetHelperError()
		}
	}

	render() {
		const { value, items, helperError } = this.props
		const { stage } = this.state
		const selected = items[value]
		return (
			<div
				className={
					stage !== stages.normal ? `Select Select--${stage}` : 'Select'
				}
			>
				<div className='Select-dropdown'>
					<ul
						ref={this.saveListRef}
						className='Select-list'
						style={{
							marginTop: stage === stages.open ? 0 : 0 - this.state.height,
						}}
					>
						{items.map((item, index) => (
							<li
								key={item.value}
								data-id={index}
								className='Select-item'
								onClick={this.handleChange}
							>
								{item.title ? item.title : item.value}
							</li>
						))}
					</ul>
				</div>
				<div
					className='Select-value'
					onClick={this.handleClick}
					style={{
						border: helperError && '2.5px solid #fc817e',
						color: helperError && 'red',
						background: helperError && '#f7d8d7',
						borderRadius: helperError && '10px',
						transition: 'all .1s ease-in-out',
					}}
				>
					{selected
						? selected.title
							? selected.title
							: selected.value
						: 'Выберите значение'}
					<span className='Select-arrow'></span>
				</div>
			</div>
		)
	}
}

export default Select
