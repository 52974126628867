export default {
  title: 'Наши работы',
  items: [
    {
      thumbnail: require('./1-small.jpg'),
      src: require('./1.jpg'),
      title: 'Пример 1'
    },
    {
      thumbnail: require('./2-small.jpg'),
      src: require('./2.jpg'),
      title: 'Пример 2'
    },
    {
      thumbnail: require('./3-small.jpg'),
      src: require('./3.jpg'),
      title: 'Пример 3'
    },
    {
      thumbnail: require('./4-small.jpg'),
      src: require('./4.jpg'),
      title: 'Пример 4'
    },
    {
      thumbnail: require('./5-small.jpg'),
      src: require('./5.jpg'),
      title: 'Пример 5'
    },
    {
      thumbnail: require('./6-small.jpg'),
      src: require('./6.jpg'),
      title: 'Пример 6'
    },
    {
      thumbnail: require('./7-small.jpg'),
      src: require('./7.jpg'),
      title: 'Пример 7'
    },
    {
      thumbnail: require('./8-small.jpg'),
      src: require('./8.jpg'),
      title: 'Пример 8'
    },
    {
      thumbnail: require('./9-small.jpg'),
      src: require('./9.jpg'),
      title: 'Пример 9'
    },
    {
      thumbnail: require('./10-small.jpg'),
      src: require('./10.jpg'),
      title: 'Пример 10'
    },
    {
      thumbnail: require('./11-small.jpg'),
      src: require('./11.jpg'),
      title: 'Пример 11'
    },
    {
      thumbnail: require('./12-small.jpg'),
      src: require('./12.jpg'),
      title: 'Пример 12'
    },
    {
      thumbnail: require('./13-small.jpg'),
      src: require('./13.jpg'),
      title: 'Пример 13'
    },
    {
      thumbnail: require('./14-small.jpg'),
      src: require('./14.jpg'),
      title: 'Пример 14'
    },
    {
      thumbnail: require('./15-small.jpg'),
      src: require('./15.jpg'),
      title: 'Пример 15'
    },
    {
      thumbnail: require('./16-small.jpg'),
      src: require('./16.jpg'),
      title: 'Пример 16'
    },
    {
      thumbnail: require('./17-small.jpg'),
      src: require('./17.jpg'),
      title: 'Пример 17'
    },
    {
      thumbnail: require('./18-small.jpg'),
      src: require('./18.jpg'),
      title: 'Пример 18'
    },
    {
      thumbnail: require('./19-small.jpg'),
      src: require('./19.jpg'),
      title: 'Пример 19'
    },
    {
      thumbnail: require('./20-small.jpg'),
      src: require('./20.jpg'),
      title: 'Пример 20'
    },
    {
      thumbnail: require('./21-small.jpg'),
      src: require('./21.jpg'),
      title: 'Пример 21'
    },
    {
      thumbnail: require('./22-small.jpg'),
      src: require('./22.jpg'),
      title: 'Пример 22'
    },
    {
      thumbnail: require('./23-small.jpg'),
      src: require('./23.jpg'),
      title: 'Пример 23'
    },
    {
      thumbnail: require('./24-small.jpg'),
      src: require('./24.jpg'),
      title: 'Пример 24'
    },
    {
      thumbnail: require('./25-small.jpg'),
      src: require('./25.jpg'),
      title: 'Пример 25'
    },
    {
      thumbnail: require('./26-small.jpg'),
      src: require('./26.jpg'),
      title: 'Пример 26'
    },
    {
      thumbnail: require('./27-small.jpg'),
      src: require('./27.jpg'),
      title: 'Пример 27'
    },
    {
      thumbnail: require('./28-small.jpg'),
      src: require('./28.jpg'),
      title: 'Пример 28'
    },
    {
      thumbnail: require('./29-small.jpg'),
      src: require('./29.jpg'),
      title: 'Пример 29'
    },
    {
      thumbnail: require('./30-small.jpg'),
      src: require('./30.jpg'),
      title: 'Пример 30'
    },
    {
      thumbnail: require('./31-small.jpg'),
      src: require('./31.jpg'),
      title: 'Пример 31'
    },
    {
      thumbnail: require('./32-small.jpg'),
      src: require('./32.jpg'),
      title: 'Пример 32'
    },
    {
      thumbnail: require('./33-small.jpg'),
      src: require('./33.jpg'),
      title: 'Пример 33'
    },
    {
      thumbnail: require('./34-small.jpg'),
      src: require('./34.jpg'),
      title: 'Пример 34'
    },
    {
      thumbnail: require('./35-small.jpg'),
      src: require('./35.jpg'),
      title: 'Пример 35'
    },
    {
      thumbnail: require('./36-small.jpg'),
      src: require('./36.jpg'),
      title: 'Пример 36'
    },
    {
      thumbnail: require('./37-small.jpg'),
      src: require('./37.jpg'),
      title: 'Пример 37'
    },
    {
      thumbnail: require('./38-small.jpg'),
      src: require('./38.jpg'),
      title: 'Пример 38'
    },
    {
      thumbnail: require('./39-small.jpg'),
      src: require('./39.jpg'),
      title: 'Пример 39'
    },
    {
      thumbnail: require('./40-small.jpg'),
      src: require('./40.jpg'),
      title: 'Пример 40'
    },
    {
      thumbnail: require('./41-small.jpg'),
      src: require('./41.jpg'),
      title: 'Пример 41'
    },
    {
      thumbnail: require('./42-small.jpg'),
      src: require('./42.jpg'),
      title: 'Пример 42'
    },
    {
      thumbnail: require('./43-small.jpg'),
      src: require('./43.jpg'),
      title: 'Пример 43'
    },
    {
      thumbnail: require('./44-small.jpg'),
      src: require('./44.jpg'),
      title: 'Пример 44'
    },
    {
      thumbnail: require('./45-small.jpg'),
      src: require('./45.jpg'),
      title: 'Пример 45'
    },
    {
      thumbnail: require('./46-small.jpg'),
      src: require('./46.jpg'),
      title: 'Пример 46'
    },
    {
      thumbnail: require('./47-small.jpg'),
      src: require('./47.jpg'),
      title: 'Пример 47'
    },
    {
      thumbnail: require('./48-small.jpg'),
      src: require('./48.jpg'),
      title: 'Пример 48'
    },
    {
      thumbnail: require('./49-small.jpg'),
      src: require('./49.jpg'),
      title: 'Пример 49'
    },
    {
      thumbnail: require('./50-small.jpg'),
      src: require('./50.jpg'),
      title: 'Пример 50'
    },
    {
      thumbnail: require('./51-small.jpg'),
      src: require('./51.jpg'),
      title: 'Пример 51'
    },
    {
      thumbnail: require('./52-small.jpg'),
      src: require('./52.jpg'),
      title: 'Пример 52'
    },
    {
      thumbnail: require('./53-small.jpg'),
      src: require('./53.jpg'),
      title: 'Пример 53'
    },
    {
      thumbnail: require('./54-small.jpg'),
      src: require('./54.jpg'),
      title: 'Пример 54'
    },
    {
      thumbnail: require('./55-small.jpg'),
      src: require('./55.jpg'),
      title: 'Пример 55'
    },
    {
      thumbnail: require('./56-small.jpg'),
      src: require('./56.jpg'),
      title: 'Пример 56'
    },
    {
      thumbnail: require('./57-small.jpg'),
      src: require('./57.jpg'),
      title: 'Пример 57'
    },
    {
      thumbnail: require('./58-small.jpg'),
      src: require('./58.jpg'),
      title: 'Пример 58'
    },
    {
      thumbnail: require('./59-small.jpg'),
      src: require('./59.jpg'),
      title: 'Пример 59'
    },
    {
      thumbnail: require('./60-small.jpg'),
      src: require('./60.jpg'),
      title: 'Пример 60'
    },
    {
      thumbnail: require('./61-small.jpg'),
      src: require('./61.jpg'),
      title: 'Пример 61'
    },
    {
      thumbnail: require('./62-small.jpg'),
      src: require('./62.jpg'),
      title: 'Пример 62'
    },
    {
      thumbnail: require('./63-small.jpg'),
      src: require('./63.jpg'),
      title: 'Пример 63'
    },
    {
      thumbnail: require('./64-small.jpg'),
      src: require('./64.jpg'),
      title: 'Пример 64'
    },
    {
      thumbnail: require('./65-small.jpg'),
      src: require('./65.jpg'),
      title: 'Пример 65'
    },
    {
      thumbnail: require('./66-small.jpg'),
      src: require('./66.jpg'),
      title: 'Пример 66'
    },
    {
      thumbnail: require('./67-small.jpg'),
      src: require('./67.jpg'),
      title: 'Пример 67'
    },
    {
      thumbnail: require('./68-small.jpg'),
      src: require('./68.jpg'),
      title: 'Пример 68'
    },
    {
      thumbnail: require('./69-small.jpg'),
      src: require('./69.jpg'),
      title: 'Пример 69'
    },
    {
      thumbnail: require('./70-small.jpg'),
      src: require('./70.jpg'),
      title: 'Пример 70'
    },
    {
      thumbnail: require('./71-small.jpg'),
      src: require('./71.jpg'),
      title: 'Пример 71'
    },
    {
      thumbnail: require('./72-small.jpg'),
      src: require('./72.jpg'),
      title: 'Пример 72'
    },
    {
      thumbnail: require('./73-small.jpg'),
      src: require('./73.jpg'),
      title: 'Пример 73'
    },
    {
      thumbnail: require('./74-small.jpg'),
      src: require('./74.jpg'),
      title: 'Пример 74'
    },
    {
      thumbnail: require('./75-small.jpg'),
      src: require('./75.jpg'),
      title: 'Пример 75'
    },
    {
      thumbnail: require('./76-small.jpg'),
      src: require('./76.jpg'),
      title: 'Пример 76'
    },
    {
      thumbnail: require('./77-small.jpg'),
      src: require('./77.jpg'),
      title: 'Пример 77'
    },
    {
      thumbnail: require('./78-small.jpg'),
      src: require('./78.jpg'),
      title: 'Пример 78'
    },
    {
      thumbnail: require('./79-small.jpg'),
      src: require('./79.jpg'),
      title: 'Пример 79'
    },
    {
      thumbnail: require('./80-small.jpg'),
      src: require('./80.jpg'),
      title: 'Пример 80'
    },
    {
      thumbnail: require('./81-small.jpg'),
      src: require('./81.jpg'),
      title: 'Пример 81'
    }
  ]
}
